import React from "react";

function MeetingLoader() {
  return (
    <div className="5xl:px-[96px] px-6 lg:px-12 w-full h-full">
      {Array.from({ length: 2 }).map((ele, index) => {
          return (
            <>
              <div key={index} className="flex items-center space-x-1 mt-0 text-xs py-[7px] rounded py-4 justify-between">
                <span className="w-1/6 rounded bg-[#f6f7f8] inline-block h-5 bg-no-repeat skeleton-animation "></span>
                <span className="w-1/6 rounded bg-[#f6f7f8] inline-block h-5 bg-no-repeat skeleton-animation "></span>
                <span></span>
              </div>
              <div className="grid grid-cols-1 py-5 mt-2 h-auto md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-7">
                  {Array.from({ length: index===0 ? 5 : 2 }).map((ele, index) => {
                      return (
                            <div key={index} className="flex flex-col p-4 bg-[#f6f7f8] space-y-5 bg-white border rounded-xl skeleton-animation">
                              <div className="flex items-center justify-center rounded-full bg-white size-10 bg-AccentMediumLight skeleton-animation "></div>
                              <div className="flex justify-between skeleton-animation bg-white "></div>
                              <div className="flex items-center space-x-3 skeleton-animation "></div>
                              <div className="flex items-center justify-end h-10 mr-1 text-xs font-semibold rounded cursor-pointer text-AccentRegular"></div>
                            </div>
                      );
                  })}
              </div>
            </>
          );
      })}
    </div>
  );
}

export default MeetingLoader;
