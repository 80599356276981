import { Menu, Transition } from "@headlessui/react";
import { Formik } from "formik";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import BackNavArrow from "../../assets/Images/back-nav-arrow.svg";
import CancelIcon from "../../assets/Images/Cancel.svg";
import CheckmarkIcon from "../../assets/Images/Checkmark.svg";
import CloseIcon from "../../assets/Images/Closedark.svg";
import SignOut from "../../assets/Images/Sign-Out.svg";
import ThreeDots from "../../assets/Images/three-dots.svg";
import SettingsIcon from "../../components/Icons/SettingsIcon";
import { LogoutAction } from "../../store/actions";
import {
  GetEventsForReminderAction,
  MarkDoneAllReminderAction,
  RemoveEventsForReminderAction,
} from "../../store/actions/eventAction";
import {
  CreateGoal,
  DeleteGoal,
  GetGoals,
  UpdateGoal,
  UpdateIsMarkGoal,
} from "../../store/actions/goalAction";
import NewActionMenuButton from "../Buttons/NewActionButton";
import TimeTrackerCard from "../common/time-tracker/TimeTrackerCard";
import CopyLinkModal from "../Modals/CopyLinkModal";
import SelectClientAndProjectModal from "../Modals/SelectClientAndProjectModal";
import StartTimerModal from "../Modals/StartTimerModal/StartTimerModal";
import GoalsEmptyState from "./components/GoalsEmptyState";
import Notification from "./components/Notification";
import ReminderEmptyState from "./components/ReminderEmptyState";

function AdminNavbar(props) {
  const inputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputEdit, setInputEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputEditValue, setInputEditValue] = useState(null);
  const [events, setEvents] = useState([]);

  const notificationsList = useSelector(
    (state) => state.notifications.notificationsList
  );
  const eventList = useSelector((state) => state.event.reminderEvents);
  const authSelector = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  const goalList = useSelector((state) => state.goal.goals);

  const [isStartTimerModalOpen, setIsStartTimerModalOpen] = useState(false);
  const [
    isSelectClientAndProjectModalOpen,
    setIsSelectClientAndProjectModalOpen,
  ] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [goal, setGoal] = useState(goalList?.data);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.socket.on("get_notification", async (data) => {
      const sortNotification = data?.data.sort((a, b) =>
        a?.createdAt < b?.createdAt ? 1 : -1
      );
      dispatch({
        type: "SET_NOTIFICATION_LIST",
        payload: sortNotification,
      });
    });
    window.socket.on("new_notification", async (data) => {
      dispatch({
        type: "SET_NEW_NOTIFICATION_LIST",
        payload: data?.data,
      });
    });

    return () => {
      window.socket.off("get_notification");
      window.socket.off("new_notification");
    };
  }, [dispatch]);

  const inputSet = () => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 500);
  };

  useEffect(() => {
    if (
      !eventList?.data?.length &&
      eventList?.data?.start_date !== moment().format("YYYY-MM-DD") &&
      eventList?.data?.end_date !==
        moment().add(30, "days").format("YYYY-MM-DD")
    ) {
      dispatch(
        GetEventsForReminderAction(
          moment().format("YYYY-MM-DD"),
          moment().add(30, "days").format("YYYY-MM-DD")
        )
      );
    }
  }, [
    dispatch,
    eventList?.data?.end_date,
    eventList?.data?.length,
    eventList?.data?.start_date,
  ]);

  useEffect(() => {
    const organizedTransactions = Object.fromEntries(
      [
        ...new Set(
          eventList?.data?.map((t) => parseInt(t.start_date.split("-")[0]))
        ),
      ].map((yr) => [
        yr,
        Object.fromEntries(
          [
            ...new Set(
              eventList?.data
                ?.filter((t) => parseInt(t.start_date.split("-")[0]) === yr)
                .map((t) => parseInt(t.start_date.split("-")[1]))
            ),
          ].map((mo) => [
            mo,
            eventList?.data?.filter(
              (t) =>
                parseInt(t.start_date.split("-")[0]) === yr &&
                parseInt(t.start_date.split("-")[1]) === mo
            ),
          ])
        ),
      ])
    );
    setEvents(organizedTransactions);
  }, [eventList]);

  useEffect(() => {
    if (!goalList.is_called) {
      dispatch(GetGoals(setGoal));
    }
  }, [dispatch, goalList.is_called]);

  useEffect(() => {
    setGoal(goalList?.data);
  }, [goalList]);

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      if (user?.time_tracker?.is_timer_running) {
        dispatch({
          type: "RELOAD_TIMER",
          payload: {
            project: user?.time_tracker?.project,
            title: user?.time_tracker?.title,
            category: user?.time_tracker?.timer_category,
            client: user?.time_tracker?.client,
            start_time: user?.time_tracker?.start_time,
            is_timer_running: user?.time_tracker?.is_timer_running,
            pause_time: user?.time_tracker?.pause_time,
            restart_time: user?.time_tracker?.restart_time,
            is_reload: false,
          },
        });
      }
    });

    return () => {
      window.removeEventListener("visibilitychange", () => {
        console.log("call visibilitychange");
      });
    };
  }, [dispatch, user]);

  const handleSave = () => {
    if (inputEditValue?._id) {
      // update API
      setLoading(true);
      const data = { goal_id: inputEditValue?._id, text: inputEditValue?.text };
      dispatch(UpdateGoal(data, setLoading, setInputEditValue, setInputEdit));
    } else {
      // create API
      setLoading(true);
      dispatch(
        CreateGoal(inputEditValue, setLoading, setInputEditValue, setInputEdit)
      );
    }
  };

  const handleChangeIsMark = (data) => {
    const xyz = goal?.map((item) => {
      return {
        ...item,
        is_marked_done:
          data?._id === item?._id
            ? !item?.is_marked_done
            : item?.is_marked_done,
      };
    });
    setGoal(xyz);
    const isMarkData = {
      goal_id: data?._id,
      is_marked: !data?.is_marked_done,
    };
    dispatch(UpdateIsMarkGoal(isMarkData));
  };

  const handleDelete = (id) => {
    dispatch(DeleteGoal(id, goalList));
  };

  const handleRemove = (id) => {
    dispatch(RemoveEventsForReminderAction(id));
  };

  const markDoneReminder = () => {
    dispatch(MarkDoneAllReminderAction(notificationsList));
  };

  const diffDays = () => {
    const date1 = moment(user?.payment?.next_bill_date);
    const date2 = moment();
    const diff = date1.diff(date2, "days");
    return diff + 1;
  };

  const handleLogout = () => {
    dispatch(LogoutAction());
  };

  return (
    <div className="md:sticky w-full z-[99] mt-[60px] md:mt-0 top-0 admin-navbar bg-Neutral000 left-0 pl-0 md:pl[240px]">
      {/* add top strip for the whitelabel */}
      {/* <div className="w-full py-3 text-sm text-center text-AccentRegular bg-AccentLight">
        🎉 Introducing White Label! Use your own custom URL + Emails.{" "}
        <a
          href="https://preview.mailerlite.com/o5k5q9r0q2"
          className="text-sm font-extrabold underline uppercase text-AccentRegular bg-AccentLight"
          target="_blank"
        >
          Read more here →
        </a>
      </div> */}

      {user?.payment?.status === "trialing" ||
      user?.payment?.status === "on_trial" ? (
        <div className="w-full py-3 text-sm text-center text-AccentRegular bg-AccentLight">
          Your Free Trial Expires in {diffDays()}
          {` `}
          days -{" "}
          <button
            className="text-sm font-extrabold underline uppercase text-AccentRegular bg-AccentLight"
            onClick={() => setIsOpen(true)}
          >
            Upgrade Now
          </button>
        </div>
      ) : null}
      {isOpen && (
        <CopyLinkModal
          linkIsOpen={isOpen}
          setLinkIsOpen={setIsOpen}
          text="End my free trial and get access to all features with no limitations."
          title="UPGRADE MY ACCOUNT"
        />
      )}

      <div className="flex justify-between items-center px-5 lg:px-12 5xl:px-[96px] border-[#EDEDF2] md:border-b bg-Neutral000 py-[20px]">
        <div className="flex items-center justify-start max-w-full md:max-w-[60%] flex-wrap">
          <div className="mr-[26px]">
            {props.backLink ? (
              <Link
                to={props.link}
                className="flex items-center mb-1 text-xs font-medium transition duration-300 ease-out text-AccentRegular hover:text-AccentMediumDark top-back-link"
              >
                <img
                  className="mr-1 transition duration-300 ease-out"
                  src={BackNavArrow}
                  alt=""
                />
                {props.backLink}
              </Link>
            ) : (
              ""
            )}
            <h3 className="text-xl font-bold text-Neutral900">{props.title}</h3>
            {props?.component ? props.component : null}
            {props.subtitle ? (
              <p className="text-xs font-medium text-Neutral700">
                {props.subtitle}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Time Tracker */}
        {user?.time_tracker?.title && user?.time_tracker?.project ? (
          <TimeTrackerCard
            user={user}
            setIsStartTimerModalOpen={setIsStartTimerModalOpen}
            show
          />
        ) : null}
        <div className="items-center hidden md:flex">
          <div className="flex items-center justify-center h-fit">
            {/* Reminders dropdown */}
            {!props.hasntReminds && (
              <Menu
                as="div"
                className="relative inline-block text-left mx-[10px]"
              >
                <div
                  className="flex items-center p-1"
                  data-for="reminders"
                  data-tip="Reminders"
                >
                  <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none popup-btn">
                    <span
                      className={`focus-visible:outline-none relative text-Neutral800 ${
                        Object?.values(events)?.length > 0
                          ? "notification-teg"
                          : null
                      }`}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Menu.Button>
                  {/* {Object?.values(events)?.length > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-[10px] font-bold text-white bg-[#6d6df2] border-2 border-white rounded-full -top-2 -right-2">
                      {Object?.values(events).map((ele) => {
                        return Object.entries(ele).map(([key, value]) => {
                          return value.length;
                        });
                      })}
                    </div>
                  )} */}
                </div>
                <ReactTooltip
                  place="bottom"
                  textColor="#F5F5F7"
                  className="tooltip-font-size"
                  backgroundColor="#292933"
                  id="reminders"
                />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute top-[36px] right-[-8px] mt-2 w-[380px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
                    <div className="flex justify-between px-6 py-5">
                      <h3 className="text-Neutral700 text-xs font-bold tracking-[0.08em] uppercase">
                        Reminders
                      </h3>
                      {Object.values(events).length ? (
                        <button
                          className="text-xs font-medium text-AccentRegular"
                          onClick={markDoneReminder}
                        >
                          Mark all as done
                        </button>
                      ) : null}
                    </div>
                    <div>
                      {Object.values(events).length === 0 && (
                        <ReminderEmptyState />
                      )}
                      <div
                        className={`checkbox-main relative pt-2 mr-2 pb-8  overflow-y-auto ${
                          Object.values(events).length === 0 ? " " : "h-[280px]"
                        }`}
                      >
                        {Object.values(events).map((ele) => {
                          return Object.entries(ele).map(([key, value]) => {
                            return (
                              <div className="px-6 pt-3">
                                <h4 className="mb-5 text-xs font-medium text-Neutral800">
                                  {moment(key).format("MMMM")}
                                </h4>
                                {value?.map((item, index) => {
                                  return (
                                    <div
                                      className="flex items-center justify-between mb-6"
                                      key={index}
                                    >
                                      <div
                                        className={`flex items-center ${
                                          item?.lead ? "cursor-pointer" : ""
                                        }`}
                                        onClick={() => {
                                          if (!item?.lead) return;
                                          navigate(
                                            `/admin/leads?cardId=${item?.lead}`
                                          );
                                        }}
                                      >
                                        <span className="bg-Neutral200 rounded-lg text-AccentRegular text-[24px] 5xl:text-[28px] h-[48px] w-[48px] flex items-center justify-center font-medium source-code-pro">
                                          {moment(item?.start_date).format(
                                            "DD"
                                          )}
                                        </span>
                                        <div className="ml-4">
                                          <h5 className="text-xs font-normal text-AccentRegular">
                                            {item?.title}{" "}
                                            <span className="font-bold text-Neutral300">
                                              @
                                              {moment(
                                                item?.start_time?.split("T")[1],
                                                "HH:mm:ss"
                                              ).format("ha")}
                                            </span>
                                          </h5>
                                          <p className="font-normal text-[10px] 5xl:text-sm text-Neutral600">
                                            Added{" "}
                                            {moment(item?.createdAt).fromNow()}
                                          </p>
                                        </div>
                                      </div>
                                      {/* {removeReminderloading ? (
                                      <img
                                        src={loader}
                                        alt=""
                                        className="animate-spin"
                                      />
                                    ) : ( */}
                                      <img
                                        className="transition duration-300 opacity-50 cursor-pointer hover:opacity-100"
                                        src={CloseIcon}
                                        alt=""
                                        onClick={() => handleRemove(item?._id)}
                                      />
                                      {/* )}/ */}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          });
                        })}
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
            {/* Notification dropdown */}
            <Notification notificationsList={notificationsList} />
            {/* Goals dropdown */}
            <Menu
              as="div"
              className="relative inline-block text-left mx-[10px]"
            >
              {({ open }) => {
                // refIsOpen.current = open;
                return (
                  <>
                    <div
                      className="flex items-center p-1"
                      data-for="goals"
                      data-tip="Goals"
                    >
                      <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none popup-btn">
                        <span className="p-1 focus-visible:outline-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 14.7276C15.5146 14.7276 18.3638 11.8784 18.3638 8.36379C18.3638 4.84917 15.5146 2 12 2C8.48537 2 5.6362 4.84917 5.6362 8.36379C5.6362 11.8784 8.48537 14.7276 12 14.7276Z"
                              stroke="#5A5A66"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.55453 13.7181L7.4545 22.0001L12.0001 19.2728L16.5456 22.0001L15.4456 13.709"
                              stroke="#5A5A66"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Menu.Button>
                    </div>
                    <ReactTooltip
                      place="bottom"
                      textColor="#F5F5F7"
                      className="tooltip-font-size"
                      backgroundColor="#292933"
                      id="goals"
                    />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute top-[36px] right-[-52px] mt-2 w-[380px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
                        <div className="flex justify-between px-6 pt-5 pb-3">
                          <h3 className="text-Neutral700 text-xs font-bold tracking-[0.08em]">
                            GOALS
                          </h3>
                          <button
                            className="text-xs font-medium text-AccentRegular"
                            onClick={() => {
                              setInputEdit(true);
                              inputSet();
                            }}
                          >
                            Create new goal
                          </button>
                        </div>
                        <div>
                          {goal.length === 0 && <GoalsEmptyState />}

                          <div
                            className={`checkbox-main relative pt-2 pb-8 overflow-y-auto ${
                              goal.length === 0 ? " " : "h-[300px]"
                            }`}
                          >
                            {/* edit input */}
                            <Formik
                              initialValues={{
                                text: "",
                              }}
                              onSubmit={handleSave}
                            >
                              {({ isSubmitting, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                  {inputEdit && setInputEdit && (
                                    <div className="bottom-[20px] w-full z-10">
                                      <div className="flex px-2 py-2 pl-5 mr-3 bg-Neutral000">
                                        <div className="border-b-[1.5px] border-AccentRegular w-full max-w-[240px] flex items-center">
                                          <input
                                            className="block w-full text-xs font-normal placeholder:text-xs placeholder:text-Neutral500 text-Neutral800 placeholder:font-normal focus-visible:outline-none"
                                            type="text"
                                            ref={inputRef}
                                            autoFocus
                                            onKeyDown={(e) =>
                                              e.stopPropagation()
                                            }
                                            placeholder="Eat more pizza"
                                            value={inputEditValue?.text}
                                            onChange={(e) => {
                                              if (inputEditValue?._id) {
                                                setInputEditValue({
                                                  ...inputEditValue,
                                                  text: e.target.value,
                                                });
                                              } else {
                                                setInputEditValue({
                                                  text: e.target.value,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="flex ml-6">
                                          <div className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer checkmarkicon">
                                            <img
                                              src={CheckmarkIcon}
                                              alt=""
                                              disabled={isSubmitting}
                                              onClick={handleSave}
                                            />
                                          </div>
                                          <div className="flex items-center justify-center w-8 h-8 ml-2 border rounded-full cursor-pointer border-AccentLight">
                                            <img
                                              src={CancelIcon}
                                              alt=""
                                              onClick={() => {
                                                setInputEdit(false);
                                                setInputEditValue(null);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </form>
                              )}
                            </Formik>
                            <div>
                              {goal?.map((item, index) => (
                                <div
                                  className="flex justify-between pl-6 pr-4 formgroupmain hover:bg-AccentLight"
                                  key={index}
                                >
                                  <div className="w-full form-group">
                                    <input
                                      type="checkbox"
                                      id={item?._id}
                                      checked={item?.is_marked_done}
                                      onChange={() => handleChangeIsMark(item)}
                                    />
                                    <label
                                      className="flex items-center w-full pt-3 pb-3 text-xs font-normal text-Neutral800 hover:text-AccentRegular"
                                      for={item?._id}
                                    >
                                      {item.text}
                                    </label>
                                  </div>
                                  <Menu
                                    as="div"
                                    className="relative flex items-center text-left"
                                  >
                                    <div className="flex items-center">
                                      <Menu.Button className="inline-flex justify-center w-full">
                                        <img src={ThreeDots} alt="" />
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="origin-top-right edit-delete-menu absolute z-[999] top-[-60px] right-0 w-[100px] rounded-lg dropdowns-boxshadow bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none">
                                        <div>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div>
                                                <div
                                                  className="w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex items-center cursor-pointer text-Neutral800 hover:text-AccentRegular font-semibold hover:bg-Neutral200 transition duration-300 download"
                                                  onClick={() => {
                                                    setInputEditValue(item);
                                                    setInputEdit(true);
                                                  }}
                                                >
                                                  <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M7.77143 4.12402H4.06027C3.77905 4.12402 3.50935 4.23574 3.3105 4.43459C3.11165 4.63344 2.99994 4.90314 2.99994 5.18436V12.6067C2.99994 12.8879 3.11165 13.1576 3.3105 13.3564C3.50935 13.5553 3.77905 13.667 4.06027 13.667H11.4826C11.7638 13.667 12.0335 13.5553 12.2324 13.3564C12.4312 13.1576 12.5429 12.8879 12.5429 12.6067V8.89552"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                    <path
                                                      d="M11.7467 3.3294C11.9576 3.11849 12.2436 3 12.5419 3C12.8402 3 13.1263 3.11849 13.3372 3.3294C13.5481 3.54032 13.6666 3.82638 13.6666 4.12465C13.6666 4.42293 13.5481 4.70899 13.3372 4.9199L8.3006 9.95648L6.17993 10.4866L6.7101 8.36598L11.7467 3.3294Z"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  <span className="ml-2">
                                                    Edit
                                                  </span>
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    handleDelete(item?._id);
                                                  }}
                                                  className="text-Neutral800 hover:text-AccentRegular w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300 download"
                                                >
                                                  <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M3.23071 5.04395H4.33157H13.1384"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                    <path
                                                      d="M5.98215 5.04444V4.02222C5.98215 3.75111 6.09814 3.49111 6.30459 3.2994C6.51104 3.1077 6.79104 3 7.08301 3H9.28472C9.57668 3 9.85669 3.1077 10.0631 3.2994C10.2696 3.49111 10.3856 3.75111 10.3856 4.02222V5.04444M12.0369 5.04444V12.2C12.0369 12.4711 11.9209 12.7311 11.7144 12.9228C11.508 13.1145 11.228 13.2222 10.936 13.2222H5.43173C5.13976 13.2222 4.85975 13.1145 4.6533 12.9228C4.44685 12.7311 4.33087 12.4711 4.33087 12.2V5.04444H12.0369Z"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  <span className="ml-2">
                                                    Delete
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </Menu.Item>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                );
              }}
            </Menu>

            <div className="ml-[18px] mr-[28px] h-5 border-r border-[#DFDFE6]"></div>
            {/* TODO: use when timer ready */}
            {/* Timer button */}
            {!user?.time_tracker?.title && !user?.time_tracker?.project ? (
              <>
                <div
                  className={`${
                    user?.role === "general" ? "mr-0" : "mr-[36px]"
                  } cursor-pointer`}
                  onClick={() => setIsSelectClientAndProjectModalOpen(true)}
                  data-for="timerWidget"
                  data-tip="Start task timer"
                >
                  <svg
                    width="48"
                    height="49"
                    viewBox="0 0 48 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="2"
                      y="2.5"
                      width="44"
                      height="44"
                      rx="22"
                      fill="var(--accent-color)" //6D6DF2
                    />
                    <path
                      d="M20 16.5L32 24.5L20 32.5V16.5Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="2"
                      y="2.5"
                      width="44"
                      height="44"
                      rx="22"
                      stroke="var(--bg-color)" //#F0F0FF
                      strokeWidth="4"
                    />
                  </svg>
                </div>
                <ReactTooltip
                  place="bottom"
                  textColor="#F5F5F7"
                  className="tooltip-font-size"
                  backgroundColor="#292933"
                  id="timerWidget"
                />
              </>
            ) : null}

            {/* New Action Button */}
            {user?.role !== "general" && (
              <NewActionMenuButton role={user?.role} />
            )}

            <div className="ml-[30px] mr-[30px] h-5 border-r border-[#DFDFE6]"></div>

            <div className="flex items-center justify-between">
              <Menu
                as="div"
                className="relative inline-block text-left min-w-[2.5rem]"
              >
                <div className="flex items-center">
                  <Menu.Button className="inline-flex justify-center w-full focus-visible:outline-none">
                    <div className="flex items-center transition-all duration-300 ease-in-out hover:cusor-pointer group">
                      {authSelector?.user?.profile ? (
                        <img
                          className="!min-w-[36px] w-10 h-10 rounded-full group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50"
                          src={authSelector?.user?.profile_path}
                          alt=""
                        />
                      ) : (
                        <div
                          className={`border border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50`}
                        >
                          <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey uppercase">
                            {authSelector?.user?.user_name?.[0]}
                          </span>
                        </div>
                      )}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-0 origin-bottom-right bg-white rounded-md w-fit drop-shadow-Simplebutton focus:outline-none">
                    <div className="">
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/admin/settings">
                              <button
                                type="button"
                                className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                              >
                                {" "}
                                <SettingsIcon />
                                <span className="ml-1"> Settings</span>{" "}
                              </button>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              onClick={handleLogout}
                              className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                            >
                              {" "}
                              <img className="mr-1" src={SignOut} alt="" />
                              Sign Out
                            </button>
                          )}
                        </Menu.Item>
                      </form>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            {isSelectClientAndProjectModalOpen && (
              <SelectClientAndProjectModal
                isOpen={isSelectClientAndProjectModalOpen}
                setIsOpen={setIsSelectClientAndProjectModalOpen}
                selectedProject={selectedProject}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                setSelectedProject={setSelectedProject}
                handleSave={() => {
                  setIsSelectClientAndProjectModalOpen(false);
                  setIsStartTimerModalOpen(true);
                }}
              />
            )}
            {isStartTimerModalOpen && (
              <StartTimerModal
                isStartTimerModalOpen={isStartTimerModalOpen}
                setIsStartTimerModalOpen={setIsStartTimerModalOpen}
                selectedProject={selectedProject}
                selectedClient={selectedClient}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNavbar;
