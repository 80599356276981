import { useCallback, useEffect, useState } from "react";

const useGetBrandIdentityForPublicRoute = (clientId) => {
  const [brandIdentity, setBrandIdentity] = useState({
    bgColor: "",
    accentColor: "",
    logo: null,
  });

  const getBrandIdentity = useCallback(async () => {
    const BASE_URL = process.env.REACT_APP_API_URL + "api/v1";

    try {
      const response = await fetch(
        `${BASE_URL}/brand-identity/get-brand-identity?type=client&id=${clientId}`
      );

      const {
        success,
        data: { bg_color, accent_color, logo, status, ...rest },
      } = await response.json();

      setBrandIdentity((prev) => ({
        ...prev,
        bgColor: bg_color,
        accentColor: accent_color,
        logo,
        status,
        ...rest,
      }));

      if (success) {
        document.documentElement.style.setProperty(
          "--bg-color",
          bg_color || "#F0F0FF"
        );
        document.documentElement.style.setProperty(
          "--accent-color",
          accent_color || "#6D6DF2"
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [clientId]);

  useEffect(() => {
    getBrandIdentity();
  }, [getBrandIdentity]);

  return { brandIdentity };
};

export default useGetBrandIdentityForPublicRoute;
