import html2pdf from "html2pdf.js";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import Download from "../../assets/Images/Download-white.svg";
import DarkNotification from "../../assets/Images/Notification-dark.svg";
import useGetBrandIdentityForPublicRoute from "../../hooks/useGetBrandIdentityForPublicRoute";
import {
  ChangeContractStatusViewedAction,
  GetPublicContentByClientIdContract,
} from "../../store/actions/documentsAction";
import syncImages from "../../utils/syncImage";
import ButtonLoader from "../Loaders/ButtonLoader";
import PublicFroalaBuilderComp from "../PublicTextEditor/PublicFroalaBuilderComp";

const PublicContractBuilder = () => {
  const { clientId, contractId } = useParams();
  const dispatch = useDispatch();
  const editor = useRef();
  const { brandIdentity } = useGetBrandIdentityForPublicRoute(clientId);
  console.log(brandIdentity);

  const [isLoading, setIsLoading] = useState(true);
  const [textEditor, setTextEditor] = useState({
    images: { url: null, key: null },
    text: null,
    is_dotted: false,
    signature: "",
  });
  const [downloadLoader, setDownloadLoader] = useState(false);

  const handleDownloadPDF = async () => {
    setDownloadLoader(true);

    if (document.getElementById("download-content")) {
      document.getElementById("download-content").remove();
    }

    const newElement = document.createElement("div");
    newElement.id = "download-content";
    newElement.style.display = "none";
    newElement.appendChild(
      document.getElementById("froala-editor-view").cloneNode(true)
    );

    document
      .getElementById("public-proposal-editor")
      .firstChild.before(newElement);

    const element = document.querySelector(
      "#download-content #froala-editor-view"
    );

    element.querySelectorAll(".fr-view table td").forEach((td) => {
      td.style.paddingBottom = "17px";
      td.style.lineHeight = "5px";
    });

    element.querySelectorAll(".fr-view table th").forEach((head) => {
      head.style.paddingBottom = "17px";
      head.style.lineHeight = "5px";
    });
    
    const customBullets = [];

    element.querySelectorAll(".page").forEach((el) => {
      el.style.height = "100%";
      el.style.minHeight = "27cm";
    });

    element.querySelectorAll(".page-break").forEach((el) => {
      el.style.height = "0px";
      el.style.margin = "0px";
      el.style.padding = "0px";
    });
    element.querySelectorAll(".page-break-label").forEach((el) => {
      el.style.display = "none";
    });

    element.querySelectorAll("ul").forEach((ul) => {
      ul.style.listStyleType = "none"; // remove default bullets
    });

    element.querySelectorAll("ul li").forEach((el) => {
      const bullet = document.createElement("span");
      bullet.style.fontSize = "20px";
      bullet.textContent = "• ";
      el.insertBefore(bullet, el.firstChild);
      customBullets.push(bullet); // keep track of custom bullets
    });

    const fileName = `contract_${textEditor?.client?.user?.user_name}_${
      textEditor?.client?.contact_name
    }_${new Date().toISOString().split("T")[0]}.pdf`;

    await syncImages();

    element.querySelectorAll(".border-Neutral300").forEach((el) => {
      el.classList.remove("border-Neutral300");
      el.classList.add("border-Neutral200");
    });

    element.querySelectorAll(".text-AccentMediumDark").forEach((el) => {
      el.classList.remove("text-AccentMediumDark");
      el.classList.add("text-AccentRegular");
    });

    if (element.querySelector("#cm_logo")) {
      element.querySelector("#cm_logo").style.marginTop = "10px";
    }

    html2pdf(element, {
      margin: 0,
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    }).catch((error) => console.log("error", error));
    setDownloadLoader(false);

    setTimeout(() => {
      element.querySelectorAll(".page-break").forEach((el) => {
        el.style.height = "auto";
        el.style.margin = "15px";
        el.style.padding = "5px 0";
      });

      element.querySelectorAll(".page-break-label").forEach((el) => {
        el.style.display = "block";
      });

      element.querySelectorAll("ul").forEach((ul) => {
        ul.style.listStyleType = "disc"; // revert to original state
      });

      customBullets.forEach((bullet) => {
        bullet.parentNode.removeChild(bullet); // remove custom bullets
      });
    }, 1000);
  };

  useEffect(() => {
    const getServerProposalContent = async () => {
      setIsLoading(true);

      const serverContent = await dispatch(
        GetPublicContentByClientIdContract(clientId, contractId)
      );

      if (serverContent?.data) {
        if (
          !serverContent?.data?.status &&
          !localStorage.getItem("jwt_access_token")
        ) {
          await dispatch(
            ChangeContractStatusViewedAction({
              status: "viewed",
              contract_id: contractId,
            })
          );
          serverContent.data.status = "viewed";
        }

        let proposalContent = serverContent?.data;

        setTextEditor({
          images: {
            url: proposalContent?.cover?.url,
            key: proposalContent?.cover?.key,
          },
          text: proposalContent?.content,
          user_signature: proposalContent?.user_signature,
          client_signature: proposalContent?.client_signature,
          client: proposalContent?.client,
        });
      }

      setIsLoading(false);
    };
    getServerProposalContent();
  }, [clientId, contractId, dispatch]);

  return (
    <div className="flex flex-col mt-[80px]">
      <div className="flex flex-col justify-end align-center mb-[40px]">
        <div className="border border-gray-300 flex items-center p-3 rounded w-[400px] md:w-[790px] mx-auto text-sm md:text-md">
          <img src={DarkNotification} alt="Notification" className="mr-2" />
          <div className="flex flex-col items-start justify-center">
            <div>Please sign this contract when you're ready.</div>
            <div>
              We'll get notified automatically when you sign this contract.
            </div>
          </div>
        </div>
        <div className="flex justify-center align-center">
          <div className="button-hover text-white font-bold text-sm right-11 w-[400px] md:w-[790px] 5xl:h-14 h-12 bg-AccentRegular items-center justify-center rounded mt-[16px] py-2 px-4 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300">
            {downloadLoader ? (
              <ButtonLoader />
            ) : (
              <button
                className="flex items-center justify-center w-full h-full"
                type="button"
                onClick={handleDownloadPDF}
              >
                <span>Download PDF</span>
                <img className="ml-1" src={Download} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
      <PublicFroalaBuilderComp
        editor={editor}
        textEditor={textEditor}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        type="contract"
      />
    </div>
  );
};

export default PublicContractBuilder;
