const initialState = {
  meetings: {
    isLoading: true,
    data: [],
  },
};

function meetingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "GET_MEETINGS_SUCCESS":
      state.meetings.isLoading = payload.isLoading;
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: payload.data,
        },
      };
    case "DELETE_MEETING_SUCCESS":
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: state.meetings.data.map((meeting) => {
            if (meeting._id === payload.parentMeetingId) {
              return {
                ...meeting,
                linked_meeting: meeting.linked_meeting.filter(
                  (linkedMeeting) =>
                    linkedMeeting._id !== payload.linkedMeetingId
                ),
              };
            }
            return meeting;
          }),
        },
      };
    case "UPDATE_MEETING_SCHEDULE_SUCCESS":
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: state.meetings.data.map((meeting) => {
            if (meeting._id === payload.data?._id) {
              return payload.data;
            }
            return meeting;
          }),
        },
      };
    case "DELETE_SCHEDULE_MEETING_SUCCESS":
      return {
        ...state,
        meetings: {
          ...state.meetings,
          data: state.meetings.data.filter((meeting) => {
            return meeting._id !== payload.data?._id;
          }),
        },
      };
    default:
      return state;
  }
}

export default meetingReducer;
